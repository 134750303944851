import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { apiClient, apiFetcher, setupAPIClient } from 'utils/api-client';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { heapIdentifyUser } from '../../context/analytics/HeapProvider';
import { handleNetworkError } from '../../lib/axios';
import { useDevTool } from '../../lib/DevTool/DevTool';

export const UserContext = React.createContext();
export const ActionContext = React.createContext();

const UserState = ({ initialStore, children }) => {
    /*
        This stores a general user store for user information
     */
    const [userStore, setUserStore] = useState(initialStore ?? {});
    // const [user, setUser] = useState(undefined);
    useDevTool('User', userStore);
    // console.log('[UserState]', userStore);

    const saveAccount = (account) => {
        setUserStore( store => {
            return { ...store, account, subscription: account.subscription };
        });
    };


    const [errorMessages, setErrorMessages] = useState([]);
    const { data, error, mutate } = useSWR('/api/me/', apiFetcher, {
        revalidateOnFocus: false,
        onSuccess: (data) => {
            // login successful
            console.debug('<UserState> User loaded', data);
            if (!data) {
                console.error('<UserState> No user data');
                return;
            }

            // save the user data
            setUserStore(store => ({ ...store, ...data }));

            // identify with analytics
            console.log('[UserState] Identify user with analytics');
            console.log('[Rudderstack] identify', data.user);
            const { rudderstack_anonymous_id, user_id } = data.user;
            if (rudderstack_anonymous_id)
                window?.rudderanalytics?.setAnonymousId(rudderstack_anonymous_id);
            window?.rudderanalytics?.identify(user_id, data.user);

            // set the user token in the api client
            // setupAPIClient(data.user?.id);


            // identify with analytics
            heapIdentifyUser(data.user, data.account)
                .then(() => {
                    console.log('[UserState] Heap analytics user identified');
                })
                .catch((err) => {
                    console.error('[UserState] Heap analytics error identifying user', err);
                });
        },
        onError: (err, key, config) => {
            console.error('GET /api/me/ ERROR:', err);
            console.dir(err);
            setErrorMessages((messages) => [...messages, err.message]);
        }
    });

    useEffect(() => {
        console.log('Setting initial store', initialStore);
        if (initialStore) {
            setUserStore(initialStore);
        }
    }, [initialStore]);

    const refreshUserState = () => {
        axios.get('/api/me/')
            .then(response => {
                const { data } = response;
                console.log('Refreshed user state:', data);
                setUserStore(data);
            })
            .catch(error => {
                console.error(error);
            });
    };

    // show loading screen until user is loaded
    if (!userStore?.user) {
        // console.log('[UserState] No user - display loading page');
        return (
            <LoadingScreen />
        );
    }

    // close a message
    const onCloseError = (error) => setErrorMessages((messages) => messages.filter(message => message !== error));

    /*
        settings payload for teacher:
        {
            name,
            grades,
            password
        }
     */

    const saveSettings = (data) => {
        // only teachers can save settings
        if (!userStore?.user?.instructor) {
            throw new Error('UserState.saveSettings only allowed for teachers');
        }
        console.log('[UserState] saving settings', data);
        // save the settings
        return apiClient.put('/api/profile/', data)
            .then(({ data }) => {
                // return the error as a rejection
                if (data.error) {
                    return Promise.reject(data.error);
                }

                // the payload replaces the instructor profile
                // FIXME: this whole thing should probably be a reducer
                setUserStore(store => {
                    return {
                        ...store,
                        user: {
                            ...store?.user,
                            instructor: {
                                ...store?.user?.instructor,
                                ...data
                            }
                        }
                    };
                });
                return data;
            })
            .catch((err) => {
                console.log('[UserState] Failure saving settings:', err);
                handleNetworkError(err);
                return Promise.reject(err);
            });
        // return apiCall(`${process.env.REACT_APP_MUZOLOGY_API_URL}api/profile/`, { method: 'put', body })
        // .then((response) => response.json())
        // .then((instructorprofile) => {
        //     console.log('Response instructor profile: ', instructorprofile);
        //     if (instructorprofile.error) {
        //         // this failed
        //         return Promise.reject(instructorprofile.error);
        //     } else {
        //         // this was successful
        //         return dispatch({ type: SET_INSTRUCTOR_PROFILE, instructorprofile });
        //     }
        // });
    };

    const updateOnboardState = (onboardState) => {
        console.log('Setting onboard state', onboardState);
        setUserStore(store => {
            return {
                ...store,
                onboard: onboardState
            };
        });
    };

    const setStudent = (student) => {
        console.log('Setting student', student);
        setUserStore(store => {
            return {
                ...store,
                user: {
                    ...store.user,
                    student: {
                        ...store.user.student,
                        ...student
                    }
                }
            };
        });
    };

    const setEnv = (key, value) => {
        setUserStore(store => {
            return {
                ...store,
                env: {
                    ...store.env,
                    [key]: value
                }
            };
        });
    };

    const actions = {
        saveSettings,
        updateOnboardState,
        setStudent,
        saveAccount,
        updateUser: mutate,
        refreshUser: refreshUserState,
        setEnv
    };
    // <ErrorMessageContainer
    //     messages={errorMessages}
    //     onClose={onCloseError}
    // >
    //     {children}
    // </ErrorMessageContainer>
    return (
        <ActionContext.Provider value={actions} displayName='User Action Context'>
            <UserContext.Provider value={userStore} displayName='User State Context'>
                {children}
            </UserContext.Provider>
        </ActionContext.Provider>
    );
};

export function useUserAction() {
    const context = React.useContext(ActionContext);
    if (context === undefined) {
        throw new Error('useUserAction must be used within a UserState provider');
    }
    return context;
}

export function useRefreshUser() {
    const context = React.useContext(ActionContext);
    if (context === undefined) {
        throw new Error('useRefreshUser must be used within a UserState provider');
    }
    // console.log('[UserState] useRefreshUser', context);
    return context.updateUser;
}

// environment variables
export function useEnv() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useEnv must be used within a UserState provider');
    }
    return context.env;
}

export function useSetEnv() {
    const context = React.useContext(ActionContext);
    if (context === undefined) {
        throw new Error('useSetEnv must be used within a UserState provider');
    }
    return context.setEnv;
}


export function useEnvironmentVariable(name, default_value = null) {
    const env = useEnv();
    if (!env)
        return default_value;
    return env[name] ?? default_value;
}


export function useAccount() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useAccount must be used within a UserState provider');
    }
    const { account } = context;
    return account;
}

export function useSubscription() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useSubscription must be used within a UserState provider');
    }
    const { subscription } = context;
    return subscription;
}

export function useProduct(product_id=null) {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useProduct must be used within a UserState provider');
    }
    const { account } = context;
    if( product_id ) {
        return account.products?.includes('times-tables');
    }
    return account.products;
}

export function useOnboardState() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useOnboardState must be used within a UserState provider');
    }
    const { onboard } = context;
    return onboard ?? {};
}

export function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserState provider');
    }
    const { user } = context;
    return user;
}


export function useTeacher() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useTeacher must be used within a UserState provider');
    }
    const { user } = context;
    return user?.instructor ?? null;
}

export function useParent() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useParent must be used within a UserState provider');
    }
    const { user } = context;
    return user?.is_parent ? user : null;
}

export function useStudent() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useStudent must be used within a UserState provider');
    }
    const { user } = context;
    return user?.student ?? null;
}

export function useChild() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useChild must be used within a UserState provider');
    }
    const { user } = context;
    return user?.is_child ? user.student : null;
}


export function useLoginStreak() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useLoginStreak must be used within a UserState provider');
    }
    return context.login_streak;
}


// user helpers
export const userIsTeacher = (user) => user && !!user.instructor;
export const userIsStudent = (user) => user && !user.instructor;
export const userIsStaff = (user) => user && user.user?.is_staff;

export default UserState;
