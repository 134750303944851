import React, { useState, useEffect, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import moment from 'moment';
import { useLoginStreak, useProduct, useUser } from 'state/User/UserState';
import { useActivity } from 'state/User/ActivityState';
import { useGuidance } from 'state/Guidance/GuidanceProvider';
import { useVideosWithStats } from 'state/Student/VideoStats';
import { LargeVideoCard, HowItWorksButton } from 'components';
import VideoPlayerOverlay from 'components/VideoPlayerOverlay/VideoPlayerOverlay';
import ChildLearningCategories from 'components/ChildLearningCategories/ChildLearningCategories';
import Modal from 'components/Modal/Modal';
import GettingStartedSlider from 'components/GettingStartedSlider/D2C/GettingStartedSlider';
import CloseButton from 'components/AnswerKey/CloseButton';
import { maybePlural } from 'lib/string';
import { useForm } from 'react-hook-form';
import { useToast } from 'hooks/toast';
import { useClassActions } from 'state/Teacher/ClassState';
import ProficiencySelection from 'app/containers/child/Home/ProficiencySelection';
import { CHILD_TUTORIAL_VIDEO_ID, TIMES_TABLES_TUTORIAL_VIDEO_ID } from '../../config';
import { apiClient, handleAPIError } from '../../utils/api-client';
import { useCourseAndVideos } from '../../state/CatalogState';
import GuidanceCards from '../GuidanceCards/GuidanceCards';

const ChildHomeBanner = ({ semiActiveState, nullState, nullWhatToDoNextVideoList }) => {
    const user = useUser();
    const login_streak = useLoginStreak();
    const addToast = useToast();
    const { register, handleSubmit, errors } = useForm();
    const { updateStudent } = useClassActions();
    const [studentLearningModal, setStudentLearningModal] = useState(false);
    const { activity, downloadActivity } = useActivity();
    const nullFirstName = user.first_name.length === 0;
    const firstNameCopy = !nullFirstName ? ` ${user.first_name}!` : '!';
    const greeting = !nullFirstName && nullState ? 'Welcome Back' : nullFirstName && nullState ? 'Welcome Back,' : 'Welcome';

    // eslint-disable-next-line react-hooks/exhaustive-deps

    // convert activity to a list of records and achievements
    const activityThisWeek = activity;
    const recordsEarned = activityThisWeek.filter((a) => a.data?.score === 100 && a.data?.quizLevel > 0);
    const recordsEarnedThisWeek = recordsEarned?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const achievements = activityThisWeek.filter((a) => a.name === 'achievement:created');
    const achievementsEarnedThisWeek = achievements?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const totalSpecificActivity = recordsEarnedThisWeek + achievementsEarnedThisWeek;
    const recordCopy = recordsEarnedThisWeek === 1 ? `${recordsEarnedThisWeek} record` : `${recordsEarnedThisWeek} records `;
    const achievementCopy = achievementsEarnedThisWeek === 1 ? `${achievementsEarnedThisWeek} achievement` : `${achievementsEarnedThisWeek} achievements`;
    const nullStateCopy = 'Ready to learn math the fast and fun way? Remember to watch videos multiple times to help the information stick!';

    const timesTableProduct = useProduct('times-tables');

    let conditionalCopy;
    switch (true) {
        case totalSpecificActivity === 0:
            conditionalCopy = 'Jump back in! Keep watching videos and taking Challenges. The more you watch, the more you learn.';
            break;
        case totalSpecificActivity === 1:
            conditionalCopy = `You earned ${achievementCopy} and ${recordCopy} in the last week. The more you watch a video…the faster you learn.`;
            break;
        case totalSpecificActivity <= 3:
            conditionalCopy = `Nice! You earned ${achievementCopy} and ${recordCopy} in the last week. Keep watching videos and taking Challenges.`;
            break;
        case totalSpecificActivity <= 7:
            conditionalCopy = `Impressive! You earned ${achievementCopy} and ${recordCopy} in the last week. Keep up the great work!`;
            break;
        case totalSpecificActivity <= 12:
            conditionalCopy = `You're a legit math rockstar! You earned ${achievementCopy} and ${recordCopy} in the last week. Let’s go!`;
            break;
        case totalSpecificActivity <= 19:
            conditionalCopy = `We are seriously impressed! You earned ${achievementCopy} and ${recordCopy} in the last week. You’re crushing it!`;
            break;
        case totalSpecificActivity >= 20:
            conditionalCopy = `This is next level! You earned ${achievementCopy} and ${recordCopy} in the last week. You're a TOP Muzology performer!`;
            break;
        default:
            break;
    }

    const videos = useVideosWithStats();

    // refresh guided actions each time this is mounted
    const { actions: suggestedActions } = useGuidance();
    // create the cards
    const activityCards = useMemo(() => {
        // {timesTableProduct ? activityCards : nullState ? nullActivityCards : activityCards;}
        if (suggestedActions?.length || timesTableProduct) {
            // normal cards
            // console.log('[app]    activityCards', suggestedActions, videos);
            let actions = suggestedActions;
            if (!suggestedActions?.length) {
                actions = [
                    {
                        action: 'image',
                        image: 'times-table-first-video'
                    },
                    {
                        action: 'image',
                        image: 'times-table-first-quiz'
                    }
                ];
            }
            // actions = [
            //     {
            //         action: 'image',
            //         image: 'times-table-crushed-it'
            //     },
            //     {
            //         action: 'image',
            //         image: 'times-table-math-star'
            //     }
            // ];
            return (
                <GuidanceCards actions={actions} videos={videos} />
            );
        } else {
            // null state cards
            // console.log('[app]    null activityCards', suggestedActions, videos);
            return nullWhatToDoNextVideoList?.map((video) => {
                return (
                    <div key={video.id} tw='w-full lg:w-1/2'>
                        <LargeVideoCard
                            key={video.id}
                            action='unlock-video'
                            video={video}
                        />
                    </div>
                );
            });
        }
    }, [nullState, nullWhatToDoNextVideoList, suggestedActions, timesTableProduct, videos]);


    const onSubmit = (data) => {
        apiClient.post('/api/studentprofile/', data)
            .then(response => {
                const { data } = response;
                if (data?.user?.id) {
                    // save the student 
                    updateStudent(data);
                    addToast('Success!', 'Your information is updated.');
                    setNameSubmit(false);
                } else {
                    console.error('error saving student profile', data);

                }
            })
            .catch(err => {
                console.error(err);
                handleAPIError(err);

            });
    };

    const whatToDoNext = (
        <div id='what-to-do-next' tw='pt-9'>
            <div tw='text-white text-xl font-bold'>
                What To Do Next
            </div>
            <div id='guidance-actions' tw='flex flex-col lg:flex-row mt-5 gap-10 h-full md:h-[342px]'>
                {activityCards}
            </div>
        </div>
    );

    const loginStreakBadge = useMemo(() => {
        if (!login_streak) return null;
        return (
            <div tw=' text-white text-sm flex '>
                <div tw='bg-gradient-to-b from-[#0CC6E1] to-[#4C3FC1] font-medium rounded-xl py-0.5 px-2.5 mr-2'>
                    🔥 Best Streak: {login_streak?.longest} {maybePlural('DAY', login_streak?.longest).toUpperCase()}
                </div>
                <div tw='bg-gradient-to-b from-[#E637A8] to-[#E68137] font-medium rounded-xl py-0.5 px-2.5 ml-2'>
                    Current Streak: {login_streak?.current} {maybePlural('DAY', login_streak?.current).toUpperCase()}
                </div>
            </div>
        );
    }, [login_streak]);

    return (
        <>
            <div tw='hidden lg:block px-0 2xl:px-5'>
                <div>
                    <div tw='flex flex-row justify-between items-center pt-0 '>
                        <div tw='text-white text-3xl font-semibold'>
                            {nullState ? 'Welcome' : 'Welcome Back,'}{firstNameCopy}
                        </div>
                        {loginStreakBadge}
                    </div>

                    <div tw='text-[#cec2f0] text-xl font-medium pt-3 flex flex-col xl:flex-row justify-between w-full gap-4 '>
                        {nullState ? nullStateCopy : conditionalCopy}
                    </div>
                </div>

                {/* <div tw='pt-14'>
                    <GettingStartedSlider />
                </div> */}

                {whatToDoNext}
            </div>

            <div tw='px-5 sm:px-0 xl:px-5 block mb-0 md:mb-[350px] lg:hidden'>

                <div tw='text-white text-sm inline-flex flex-col tiny:flex-row gap-4'>
                    <div tw='bg-gradient-to-b from-[#0CC6E1] to-[#4C3FC1] font-medium rounded-xl py-1 px-2'>
                        🔥 Best Streak: {login_streak?.longest} {maybePlural('DAY', login_streak?.longest).toUpperCase()}
                    </div>
                    <div tw='bg-gradient-to-b from-[#E637A8] to-[#E68137] font-medium rounded-xl py-1 px-3'>
                        Current Streak: {login_streak?.current} {maybePlural('DAY', login_streak?.current).toUpperCase()}
                    </div>
                </div>

                <div tw='text-white text-3xl pt-5 font-semibold'>
                    {nullState ? 'Welcome' : 'Welcome Back,'}{firstNameCopy}
                    {/* {greeting}{firstNameCopy} */}

                </div>

                <div tw='text-[#9D91BB] text-xl font-medium pt-4 flex justify-between w-full flex-col'>
                    {conditionalCopy}
                </div>

                <HowItWorksButton buttonClass={'blue-dtc'}/>
                {/* <div tw='pt-14'>
                    <GettingStartedSlider />
                </div> */}

                {whatToDoNext}

            </div>

            <Modal
                isOpen={studentLearningModal}
                setIsOpen={(value) => {
                    setStudentLearningModal(value);
                }}
                modalChildrenStyle={tw`max-w-[740px] mx-auto`}
            >
                <div tw='relative rounded bg-white'>
                    <ChildLearningCategories handleModal={setStudentLearningModal} user={user} />
                    <CloseButton
                        tw='absolute -top-4 -right-4'
                        onClick={() => setStudentLearningModal(false)} />
                </div>
            </Modal>

        </>
    );
};

export default ChildHomeBanner;
