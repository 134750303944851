import React, { useEffect, useMemo, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import VideoContainer from 'app/containers/shared/VideosContainer/VideosContainer';
import LogoutRedirect from 'utils/LogoutRedirect';
import StudentApp from 'app/UserApps/StudentApp';
import TeacherApp from 'app/UserApps/TeacherApp';
import { useScrollPositionEvent } from 'context/ScrollState';
import { apiFetcher } from 'utils/api-client';
import { DEFAULT_FOOTER_HEIGHT, withFixedLayout, withScrollLayout } from 'app/layout/Layouts';
import { useTeacher, useUser, useParent, useStudent, useAccount } from 'state/User/UserState.js';
import { Error404Page } from 'components';
import { useCatalogDispatch } from 'state/CatalogState.js';
import AboutInfo from '../components/About/AboutInfo';
import Footer from '../components/Footer/Footer.js';
import TrialBanner from '../components/TrialBanner/TrialBanner.js';
import WebTour from '../components/WebTour/WebTour.js';
import { useOnboardState } from '../state/User/UserState';
import TanoshiRedirect from '../components/Tanoshi/TanoshiRedirect';
import QuizSession from './containers/shared/QuizSessionContainer/QuizSession';
import AdminApp from './UserApps/AdminApp';
import ParentApp from './UserApps/ParentApp';
import QuizQuestionPreview from './containers/admin/preview/QuizQuestionPreview';
import ChildApp from './UserApps/ChildApp';
import { profileIsAdmin } from './util';
import 'animate.css/animate.min.css';
import { useProduct } from 'state/User/UserState';

/*
    root style
    .main-content-container
*/

const Container = styled.div`
  // top-margin: 50px;
  // position: relative;
  // height: 100px;
  // width: 100%;

  // full layout
  //overflow-y: hidden;
  //position: relative;

  // fixed layout
  //&.layout-fixed {
  overflow-y: ${(props) => (props.$pathName === '/videos' ? 'hidden' : 'auto')};
    //overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  left: ${(props) => (props.$sideNav ? '0' : 0)};
  right: 0;
  bottom: 0;
    /* top: ${(props) => (props.$dtc ? '64px' : '0px')}; */
  top: 64px;
  //}
    z-index: 6;
  background: #fff;
  user-select: none;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  @media only screen and (max-width: 514px) {
    //overflow-y: scroll;
  }

  @media only screen and (max-width: 414px) {
    background-image: linear-gradient(-29deg, #59aee2 0%, #956aec 100%);
  }
  //::-webkit-scrollbar {
  //    width: 0px; /* remove scrollbar space */
  //    background: transparent; /* optional: just make scrollbar invisible */
  //}
`;

const FixedFooter = styled(Footer)`
  background: #342974;
  flex: 0 0 ${DEFAULT_FOOTER_HEIGHT}px;
`;

const map_with_id = (id_field = 'id') => {
    return function (data) {
        // console.log('transforms.map_with_id', id_field, data);
        if (!data)
            return {};
        return data.reduce((dict, item) => {
            // const id = item[id_field];
            // dict[id] = item;
            // // console.log('map_with_id', dict);
            // return dict;
            return { ...dict, [item[id_field]]: item };
        }, {});
    };
};

const MainContent = ({ className, style }) => {
    // these hooks cause redraws as the data loads
    console.log('[App] <MainContent> render');
    const user = useUser();
    const student = useStudent();
    const teacher = useTeacher();
    const parent = useParent();
    const onboard = useOnboardState();
    const catalogDispatch = useCatalogDispatch();
    // current URL
    const location = useLocation();
    // const account = useAccount();
    // const { is_parent_account } = useAccount();
    // const courseId = useSelector(getMainCourseID);
    const { is_parent_account: isChild } = user;
    const { main_course_id: courseId } = user;
    const isAdmin = profileIsAdmin(user);
    const product = useProduct();
    const timesTableProduct = product.includes('times-tables')

    useEffect(() => {
        console.debug('<MainContent> useEffect - loading courses & playlists');
        /*
        const fetchCourses = async () => {
            console.log('<MainContent> fetchCourses:');
            const courses = await apiFetcher('/api/courses/');
            if (courses) {
                for (const course of courses) {
                    catalogDispatch({ type: 'ADD_COURSES', payload: { [course?.id]: course } });
                    const playlists = await apiFetcher(`/api/courses/${course.id}/playlists/`);
                    const playlistData = map_with_id('id')(playlists);
                    catalogDispatch({ type: 'ADD_PLAYLISTS', payload: playlistData });
                }
                // catalogDispatch({ type: 'ADD_COURSES', payload: { [course?.id]: course } });
                // const playlists = await apiFetcher(`/api/courses/${courseId}/playlists/`);
                // const playlistData = map_with_id('id')(playlists);
                // catalogDispatch({ type: 'ADD_PLAYLISTS', payload: playlistData });
            }
            return courses;
        };
        fetchCourses().then(() => {
            console.log('loaded courses');
        }).catch((error) => {
            console.error('Error loading courses', error);
        });
        */

        const fetchCourse = async (courseId) => {
            console.log('<MainContent> fetchCourse: ', courseId);
            const course = await apiFetcher(`/api/courses/${courseId}/`);
            if (course) {
                catalogDispatch({ type: 'ADD_COURSES', payload: { [course?.id]: course } });
                const playlists = await apiFetcher(`/api/courses/${courseId}/playlists/`);
                const playlistData = map_with_id('id')(playlists);
                catalogDispatch({ type: 'ADD_PLAYLISTS', payload: playlistData });
            }
            return course;
        };


        /*
        // if (courseId) {
        //     fetchCourse(courseId).then(() => {
        //     });
        // }

        if (isChild) {
            // FOR D2C:
            // fetchCourses()
            //     .then(() => {
            //         console.log('loaded courses');
            //     })
            //     .catch((error) => {
            //         console.error('Error loading courses', error);
            //     });
            // courses by category and grade
            // fetchCourse('a7be8d0c-1e0f-47fa-9bcd-a7f9fb5a0358')
            //     .then(() => {
            //         console.log('loaded course 1');
            //     });
            // fetchCourse('e2d99bb6-19ca-4269-8bb6-a21bd86c2a99')
            //     .then(() => {
            //         console.log('loaded course 2');
            //     });
        } else {
            // FOR B2B:
            // pre-algebra
            // fetchCourse('891dafea-8e12-4187-93d1-e869b036d2a3')
            //     .then(() => {
            //         console.log('loaded course 1');
            //     });
        }*/

    }, [catalogDispatch, courseId, isChild]);

    // scroll to top on route change
    const container = useScrollPositionEvent();
    useEffect(() => {
        // const onLocationChange = (location) => {
        //     console.debug('Location change: ', location);
        //     // scroll container back to top on each route change
        //     if (container.current) {
        //         container.current.scrollTop = 0;
        //     }
        // };
        // return history.listen(onLocationChange);
        if (container.current) {
            container.current.scrollTop = 0;
        }
    }, [location, container]);

    // show a dialog if the user is a student and not enrolled in any classes
    const isStudentOrphan = useMemo(() => {
        return !teacher && student.classes.length === 0;
    }, [student.classes.length, teacher]);

    // // show trial end dialog within 5 days of trial being over and they have never responded
    // // also show the dialog of the trial is over
    // const show_trial_end = (account.trial_days_remaining < 0) || (account.trial_days_remaining < 5 && !onboard?.trial_end_survey_response);
    // // const show_trial_end = (((!onboard?.trial_end_survey_response) && (account.trial_days_remain < 5)) || (account.trial_days_remain < 0));
    //
    // console.log('onboard?.trial_end_survey_response', onboard?.trial_end_survey_response);
    // console.log('account.trial_days_remaining ', account.trial_days_remaining);
    // console.log('show_trial_end', show_trial_end);

    let app = null;
    if (parent)
        app = <ParentApp />;
    else if (teacher)
        app = <TeacherApp />;
    else if (isChild)
        app = <ChildApp />;
    else
        app = <StudentApp />;

    return (
        <Container
            ref={container}
            className={`main-content-container ${className ?? ''}`}
            style={style}
            $pathName={location.pathname}
            $sideNav={user.is_parent_account}
            $dtc={user.is_parent_account}
        // onScroll={setScrollPosition}
        >
            <Routes>
                <Route path='/videos/:action?/:playlist_id?/:assignment_id?' element={isChild || parent ? <Error404Page /> : <VideoContainer />} />
                <Route path='/logout' element={<LogoutRedirect />} />
                <Route path='/login' element={<LogoutRedirect />} />
                <Route path='/tanoshi/:token' element={<TanoshiRedirect />} />

                <Route path='/preview/:quiz_id/question/:question_id' element={withScrollLayout(QuizQuestionPreview)} />

                {/* App routes */}
                <Route path='*' element={app} />

                {/* Admin routes */}
                {isAdmin && <Route path='/admin/*' element={<AdminApp />} />}

                {/*<Route path='/about' element={<AboutInfo />} />*/}

            </Routes>

            {onboard?.enable_demo_mode && <WebTour />}

            {/*{onboard?.show_new_platform_message && <V1UserFirstTimeLogin />}*/}

            {/*<UnenrolledStudentDialog show={showOrphanDialog} close={null} /> */}
            {(isChild && !timesTableProduct) && <TrialBanner />}
            <FixedFooter />
        </Container>
    );
};

export default MainContent;
