import React, { useRef, useState } from 'react';
import { Modal, LoginButton } from 'components';
import tw from 'twin.macro';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { useQuery } from 'hooks/useQuery';
import qs from 'qs';

const ResetPassword = ({ isOpen, setIsOpen, setIsLoading }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    const togglePasswordVisibility = (event) => {
        event.preventDefault();
        setIsPasswordVisible(!isPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = (event) => {
        event.preventDefault();
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };

    const {
        register,
        handleSubmit,
        watch,
        errors,
        formState: { isSubmitting }
    } = useForm();

    const { reset } = useQuery();
    const navigate = useNavigate();
    const [{ loading: isPosting }, postPasswordReset] = useAxios(
        {
            url: 'api/password_reset/confirm/',
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
        },
        { manual: true }
    );

    const password = useRef({});
    password.current = watch('password', '');

    const onSubmit = (data) => {
        data['token'] = reset;
        delete data['confirmPassword'];
        setIsLoading(true);
        postPasswordReset({ data: qs.stringify(data) })
            .then((response) => {
                console.log(response);
                setIsOpen(false);
                window.location.href = '/';
            })
            .catch((error) => {
                setIsLoading(false);
                if (error?.response?.status === 404) {
                    // this token is not valid
                    navigate('/login');
                }
                console.log('Password Reset error:', error);
                console.error(error);
            });
    };


    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full max-w-[700px] mx-4 md:mx-auto`} modalChildrenStyleOpacity={tw`opacity-80`}>

            <>
                <div tw='bg-[#3E257A] font-Poppins flex flex-col items-center rounded-t-2xl relative px-3'>
                    <div tw='absolute top-3 right-3' onClick={() => setIsOpen(false)}>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M16.3398 9.3218C16.4358 9.23224 16.5133 9.12464 16.5678 9.00513C16.6223 8.88563 16.6527 8.75656 16.6573 8.62531C16.6619 8.49405 16.6406 8.36318 16.5946 8.24015C16.5486 8.11713 16.4788 8.00437 16.3893 7.90831C16.2997 7.81224 16.1921 7.73476 16.0726 7.68029C15.9531 7.62581 15.824 7.59541 15.6928 7.59081C15.5615 7.58621 15.4306 7.60751 15.3076 7.6535C15.1846 7.69948 15.0718 7.76924 14.9758 7.85881L12.0498 10.5868L9.32176 7.65981C9.13923 7.47285 8.89078 7.36476 8.62956 7.35867C8.36834 7.35258 8.11512 7.44897 7.92407 7.62721C7.73302 7.80545 7.61932 8.05139 7.6073 8.3124C7.59529 8.57341 7.6859 8.82876 7.85977 9.02381L10.5878 11.9498L7.66077 14.6778C7.56131 14.7665 7.48057 14.8741 7.4233 14.9944C7.36602 15.1147 7.33336 15.2452 7.32723 15.3783C7.3211 15.5114 7.34163 15.6444 7.38761 15.7694C7.43359 15.8944 7.5041 16.009 7.59498 16.1065C7.68587 16.2039 7.79531 16.2822 7.91687 16.3367C8.03843 16.3912 8.16966 16.4209 8.30286 16.424C8.43605 16.4271 8.56853 16.4036 8.6925 16.3548C8.81647 16.306 8.92944 16.2329 9.02476 16.1398L11.9508 13.4128L14.6788 16.3388C14.7668 16.4401 14.8744 16.5226 14.9951 16.5814C15.1157 16.6403 15.247 16.6742 15.381 16.6812C15.5151 16.6883 15.6492 16.6682 15.7753 16.6223C15.9015 16.5764 16.0171 16.5056 16.1153 16.414C16.2134 16.3225 16.2922 16.2121 16.3468 16.0895C16.4014 15.9669 16.4308 15.8345 16.4332 15.7003C16.4356 15.5661 16.4109 15.4328 16.3607 15.3083C16.3104 15.1839 16.2356 15.0708 16.1408 14.9758L13.4138 12.0498L16.3398 9.3218Z' fill='#9D91BB' />
                            <path fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z' fill='#9D91BB' />
                        </svg>
                    </div>
                    <div tw='text-white text-xl font-semibold text-center max-w-[380px] mt-10 '>PASSWORD RESET</div>
                    <div tw='text-[#9D91BB] font-normal text-sm mt-1'>Type in your new password.</div>
                    <form tw='w-full max-w-[350px] pb-9' onSubmit={handleSubmit(onSubmit)}>
                        <div tw='relative mt-5 text-white'>
                            <input
                                className='input'
                                type={isPasswordVisible ? 'text' : 'password'}
                                name='password'
                                ref={register({
                                    required: true,
                                    minLength: { value: 6, message: 'Password must have at least 6 characters' }
                                })}
                                placeholder='Create New Password'
                                tw='block w-full pl-4 pr-4 py-2.5 mt-2 font-normal text-base text-white bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'
                            />
                            <button
                                type='button'
                                tw='absolute p-2 border-none right-0 top-0 bottom-0 w-[36.5px] text-white hover:text-[#e68137] active:text-[#e637a8] bg-[transparent] '
                                onClick={togglePasswordVisibility}
                            >
                                <svg tw='h-full' width='100%' height='100%' viewBox='0 0 24 24' fill='red' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12 4C16.2909 4 19.8627 6.4287 22.6646 11.154L22.8844 11.5329L22.9302 11.6328L22.9591 11.716L22.9736 11.7706L22.988 11.853L22.999 11.9535L22.9982 12.0639L22.9849 12.1744C22.9784 12.2112 22.9698 12.2477 22.9591 12.284L22.9203 12.3916L22.8844 12.4671L22.8682 12.4962C20.1039 17.3334 16.5685 19.8773 12.3127 19.9957L12 20C7.6044 20 3.96348 17.4514 1.13177 12.4962C0.956077 12.1887 0.956077 11.8113 1.13177 11.5038C3.96348 6.54862 7.6044 4 12 4ZM12 6C8.59033 6 5.69918 7.89478 3.27859 11.8059L3.161 12L3.27859 12.1941C5.63002 15.9935 8.42551 17.8901 11.709 17.9954L12 18C15.4097 18 18.3008 16.1052 20.7214 12.1941L20.838 12L20.7214 11.8059C18.37 8.00652 15.5745 6.10992 12.291 6.00464L12 6ZM12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11Z'
                                        fill='currentColor'
                                    />
                                </svg>
                            </button>
                            {errors.password && <div tw='pt-2 text-red-500'>{errors.password.message ? errors.password.message : 'This field is required.'}</div>}
                        </div>
                        <div tw='relative mt-5 text-white'>
                            <input
                                className='input'
                                type={isConfirmPasswordVisible ? 'text' : 'password'}
                                name='confirmPassword'
                                ref={register({ validate: (value) => value === password.current || 'The passwords do not match' })}
                                placeholder='Confirm Password'
                                tw='block w-full pl-4 pr-4 py-2.5 mt-2 font-normal text-base text-white bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'
                            />
                            <button
                                type='button'
                                tw='absolute p-2 border-none right-0 top-0 bottom-0 w-[36.5px] text-white hover:text-[#e68137] active:text-[#e637a8] bg-[transparent] '
                                onClick={toggleConfirmPasswordVisibility}
                            >
                                <svg tw='h-full' width='100%' height='100%' viewBox='0 0 24 24' fill='red' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12 4C16.2909 4 19.8627 6.4287 22.6646 11.154L22.8844 11.5329L22.9302 11.6328L22.9591 11.716L22.9736 11.7706L22.988 11.853L22.999 11.9535L22.9982 12.0639L22.9849 12.1744C22.9784 12.2112 22.9698 12.2477 22.9591 12.284L22.9203 12.3916L22.8844 12.4671L22.8682 12.4962C20.1039 17.3334 16.5685 19.8773 12.3127 19.9957L12 20C7.6044 20 3.96348 17.4514 1.13177 12.4962C0.956077 12.1887 0.956077 11.8113 1.13177 11.5038C3.96348 6.54862 7.6044 4 12 4ZM12 6C8.59033 6 5.69918 7.89478 3.27859 11.8059L3.161 12L3.27859 12.1941C5.63002 15.9935 8.42551 17.8901 11.709 17.9954L12 18C15.4097 18 18.3008 16.1052 20.7214 12.1941L20.838 12L20.7214 11.8059C18.37 8.00652 15.5745 6.10992 12.291 6.00464L12 6ZM12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11Z'
                                        fill='currentColor'
                                    />
                                </svg>
                            </button>
                            {errors.confirmPassword && <div tw='pt-2 text-red-500'> {errors.confirmPassword.message ? errors.confirmPassword.message : 'This field is required.'}</div>}
                        </div>
                        <div tw='mt-5'>
                            <LoginButton
                                id='login-button'
                                variant='Blue'
                                as='button'
                                type='submit'
                                data-testid='submit'

                            >
                                <div tw='flex items-center justify-center w-full'>
                                    <div tw='ml-2'>
                                        Reset Password
                                    </div>
                                </div>

                            </LoginButton>
                        </div>
                    </form>
                </div>
                <div tw='bg-[#6441B9] text-center text-white text-sm font-normal  pb-5 pt-4 rounded-b-2xl font-Poppins px-3'>

                    <div tw='text-white font-normal text-sm w-full mx-auto max-w-[430px] mt-0'>
                        Need Help? Contact us at - <a href='mailto:support@muzology.com' target='_blank' rel='noreferrer' tw='underline'>
                            support@muzology.com
                        </a>
                    </div>
                </div>
            </>
        </Modal>
    );
};

export default ResetPassword;