import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { Modal } from 'components';
import {
    ONBOARD_STAGE_AGREE_TO_TERMS,
    ONBOARD_STAGE_SETUP_CHILD,
    ONBOARD_STAGE_SETUP_TUTORIAL_VIDEO,
    ONBOARD_STAGE_SUBSCRIPTION_CANCELLED,
    ONBOARD_STAGE_TRIAL_EXPIRED,
    ONBOARD_STAGE_TRIAL_EXPIRING_SOON,
    ONBOARD_STAGE_VERIFY,
    ONBOARD_REGISTRATION_REQUIRED,
    ONBOARD_ALL_ACCESS_ACCOUNT_CREATION,
    ONBOARD_ALL_ACCESS_ACCOUNT_SETUP,
    ONBOARD_TIMES_TABLE

} from 'app/AccountOnboard/OnboardStages';
import { useOnboardStageFinished } from 'state/OnboardState';
import CancelSubscriptionPopUp from '../CancelSubscriptionPopUp/CancelSubscriptionPopUp';
import EndTrialDialog from '../EndTrailDialog/EndTrialDialog';
import AgreeToTerms from './Stages/AgreeToTerms';
import CreatChildAccount from './Stages/CreatChildAccount';
import CreateMathPlaylist from './Stages/CreateMathPlaylist';
import CreateAccount from './UserTypes/FullMuzology/CreateAccount';
import SetupAccount from './UserTypes/FullMuzology/SetupAccount';
import TimesTable from './UserTypes/TimesTable';
import Teacher from './UserTypes/Teacher';
import Intro from './Stages/Intro';
import { TutorialVideoPlayer } from './Stages/TutorialVideoPlayer';
import { CreateChildProvider } from './Stages/CreateChildProvider';

const OnBoarding = ({ stage, setOnboardState }) => {
    console.log("🚀 ~ OnBoarding ~ stage:", stage)
    const [isOpen, setIsOpen] = useState(false);
    const stageFinished = useOnboardStageFinished();
    const [childrenStyle, setChildrenStyle] = useState(tw`w-full max-w-[820px] mx-auto`);

    // console.log('🚀 ~ OnBoarding ~ childrenStyle:', childrenStyle);
    useEffect(() => {
        if (stage === ONBOARD_STAGE_AGREE_TO_TERMS) setChildrenStyle(tw`w-full max-w-[768px] lg:max-w-[1100px] xl:max-w-[1200px] mx-auto`);
        if (stage === ONBOARD_ALL_ACCESS_ACCOUNT_CREATION || ONBOARD_ALL_ACCESS_ACCOUNT_SETUP || ONBOARD_TIMES_TABLE) setChildrenStyle(tw`w-full max-w-[380px] sm:max-w-[768px] lg:max-w-[1100px] xl:max-w-[1200px] mx-auto`);
        // else setChildrenStyle(tw`w-full max-w-[820px] mx-auto`);
    }, [stage]);

    const renderCurrentStage = () => {
        /* this ensures the stages appear in the correct order */
        switch (stage) {
            // verify email for teacher account 
            case ONBOARD_STAGE_VERIFY:
                return (
                    <CreateChildProvider>
                        <div tw='mx-4 relative'>
                            <div tw='bg-white p-6 rounded-xl max-w-[800px] mx-auto'>
                                <Intro />
                            </div>
                        </div>
                    </CreateChildProvider>
                );

            // TODO rename this to stage to math the user teacher 
            case ONBOARD_STAGE_AGREE_TO_TERMS:
                return (
                    <Teacher setOnboardState={setOnboardState} isOpen={isOpen} />
                );

            case ONBOARD_STAGE_SETUP_CHILD:
                // add a child account
                return (
                    <CreateChildProvider>
                        <div tw='mx-4 relative'>
                            <div tw='bg-white p-6 rounded-xl'>
                                <CreatChildAccount />
                            </div>
                        </div>
                    </CreateChildProvider>
                );

            // All access account creation - user sets username and password
            case ONBOARD_ALL_ACCESS_ACCOUNT_CREATION:
                return (
                    <CreateAccount setOnboardState={setOnboardState} onboardFlag={{ all_access_account_creation: false }} />
                );

            // All access account setup - user sets selects grade level and math proficiency 
            case ONBOARD_ALL_ACCESS_ACCOUNT_SETUP:
                return (
                    <SetupAccount setOnboardState={setOnboardState} onboardFlag={{ all_access_account_setup: false }} />
                );

            // Times Table account - user sets password, username, grade level 
            case ONBOARD_TIMES_TABLE:
                return (
                    <TimesTable setOnboardState={setOnboardState} onboardFlag={{ times_tables_onboard: false }} />
                );

            case ONBOARD_STAGE_SETUP_TUTORIAL_VIDEO:
                return (
                    <TutorialVideoPlayer stageFinished={stageFinished} />
                );

            // case ONBOARD_STAGE_SUBSCRIPTION_CANCELLED:
            //     // the subscription has been cancelled
            //     return (
            //         <CancelSubscriptionPopUp />
            //     );

            case ONBOARD_STAGE_TRIAL_EXPIRING_SOON:
            case ONBOARD_STAGE_TRIAL_EXPIRED:
                // the trial has expired
                return (
                    <EndTrialDialog setOnboardState={setOnboardState} show={true} />
                );

            default:
                console.log('Unknown stage: ', stage);
                return <div>Unknown Stage: {stage}</div>;
        }
    };

    if (!stage) {
        return null;
    }

    return (
        <Modal isOpen={true} setIsOpen={null} fakeSetIsOpen={setIsOpen} modalChildrenStyle={childrenStyle}>

            {renderCurrentStage()}

        </Modal>
    );
};

export default OnBoarding;
