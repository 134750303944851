import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

const StyledButton = styled.button(({ $variant, $iconLeft, $iconRight, $disabled, $size }) => [
    tw`py-2 px-8 bg-PinkCrayola rounded-full uppercase text-sm font-extrabold leading-normal text-white cursor-pointer`,
    $variant === 'secondary' && tw`bg-GainsboroGrey text-DarkLiver`,
    $variant === 'hotPink' && tw`bg-FrostBite`,
    $variant === 'purple' && tw`bg-MediumPurple `,
    $variant === 'teal' && tw`bg-MuzologyLightBlue `,
    $variant === 'create-playlist' && tw`border[1px solid white]`,
    $variant === 'gradient' && tw`bg-gradient-to-r to-PurpPurple from-FrostBite shadow-xl`,
    $variant === 'gradient-orange' && tw`background[linear-gradient(135deg, #E637A8 2.69%, #E68137 75%)] shadow-xl`,
    $variant === 'teal-large' && tw`bg-MuzologyLightBlue py-1.5 xl:py-2.5 px-4 xl:px-8 text-sm md:text-base lg:text-lg`,
    $size === 'large' && tw`py-1.5 xl:py-2.5 lg:px-6 xl:px-8 text-sm md:text-base lg:text-lg bg-FrostBite`,
    $variant === 'Review' && tw`bg-white text-black`,
    $variant === 'Locked' && tw`bg-DavysGrey `,
    $variant === 'StudentDashBoard' && tw`bg-white text-black underline`,
    $variant === 'RosterTable' && tw`bg-GainsboroGrey text-DavysGrey `,
    $variant === 'ParentSettings' && tw`border border-color[#A5A5A5] color[#A5A5A5] bg-white hover:background[#A5A5A5] hover:text-white`,
    $variant === 'Login' && tw``,
    $variant === 'LoginGradient' && tw``,
    tw`hover:bg-opacity-80 active:bg-opacity-50 focus-visible:outlineColor[#7675e7]`,
    $variant === 'locked-dtc' && tw` bg-[#3E257A] rounded-lg text-white font-Poppins px-7 py-3  text-sm font-medium disabled:bg-opacity-40 cursor-not-allowed `,
    $variant === 'active-dtc' && tw` bg-[#FEEA50] rounded-lg text-[#1C0057] font-Poppins px-7 py-3  text-sm font-medium hover:bg-[#FEC32C] active:bg-[#DEC30C]`,
    $variant === 'review-dtc' && tw` bg-[#6160D9] rounded-lg text-white font-Poppins px-7 py-3 text-sm font-medium hover:bg-[#3130AF] active:bg-[#21209F]`,
    $variant === 'blue-dtc' && tw`bg-[#6160D9] text-white p-4 rounded-lg  hover:bg-[#3130AF] normal-case`,
    $variant === 'how-it-works-dtc' && tw`bg-[#6160D9] text-white p-2 xl:p-4 rounded-lg normal-case transition-all duration-200 hover:bg-[#7170FF] text-xs font-medium`,
    $variant === 'how-it-works-dtc-settings' && tw`bg-[#6160D9] text-white p-2 xl:p-4 rounded-lg normal-case transition-all duration-200 hover:bg-[#7170FF] text-base font-medium min-w-[170px] md:min-w-[200px]`,
    $variant === 'upgrade' && tw`bg-transparent border-[1px] border-solid border-white text-white p-2 xl:p-4 rounded-lg normal-case min-w-[88px] xl:min-w-[130px] min-h-[48px] transition-all duration-200 hover:bg-white hover:text-[#6160D9]`,
    $variant === 'dark-blue-dtc' && tw`bg-[#51379C] text-white p-4 rounded-lg  hover:bg-[#5330AF] normal-case`,
    $variant === 'yellow-dtc' && tw`bg-[#FEEA50] text-[#1C0057] font-Poppins p-4 rounded-lg normal-case hover:bg-[#FEC32C] active:bg-[#EEB31C]`,
    $variant === 'white-dtc' && tw`bg-white text-[#1C0057] font-Poppins p-3 rounded-lg text-sm font-medium hover:bg-[#FEC32C] `,
    $variant === 'silver-dtc' && tw`bg-white text-[#1C0057] font-Poppins p-3 xsm:p-4 rounded-lg hover:bg-[#d0d0d0] normal-case`,
    $variant === 'gold-dtc' && tw`bg-white text-[#1C0057] font-Poppins p-3 xsm:p-4 rounded-lg hover:bg-[#F2B845] normal-case`,
    $variant === 'platinum-dtc' && tw`bg-white text-[#1C0057] font-Poppins p-3 xsm:p-4 rounded-lg hover:bg-[#24A6D3] normal-case`,


    $disabled && tw`cursor-not-allowed bg-PinkCrayola disabled:bg-opacity-40 text-white`,
    $iconLeft && tw`flex items-center space-x-2`

]);

export const Button = ({ children, variant, iconLeft, iconRight, disabled, size, ...props }) => {
    return (
        <StyledButton
            {...props}
            $variant={variant}
            $iconLeft={iconLeft}
            $iconRight={iconRight}
            $disabled={disabled}
            $size={size}
        >
            {iconLeft && <div>{iconLeft}</div>}
            <div>{children}</div>
            {iconRight && <div>{iconRight}</div>}
        </StyledButton>
    );
};

Button.propTypes = {
    children: PropTypes.any,
    variant: PropTypes.string,
    size: PropTypes.string,
    /** pass a component */
    iconLeft: PropTypes.element,
    /** pass a component */
    iconRight: PropTypes.element,
    disabled: PropTypes.bool
};

export default Button;
