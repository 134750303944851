import React, { useMemo, useRef, useEffect, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import BackgroundImage from 'lib/components/BackgroundImage';
import CreateMathPlaylistBackground from 'static/images/background_images/CreateMathPlaylist.png';
import TimesTablesCreateMathPlaylistBackground from 'static/images/background_images/TimesTablesCreateMathPlaylistBackground.jpg';
import { useUser } from 'state/User/UserState';
import { apiClient, handleAPIError } from 'utils/api-client';
import { useUserAction } from 'state/User/UserState';
import { ONBOARD_STAGE_AGREE_TO_TERMS } from 'app/AccountOnboard/OnboardStages';
import { useOnboardStageFinished } from 'state/OnboardState';
import { useProduct } from 'state/User/UserState';
import { useMedia } from 'react-use';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';
import { LoadingOverlay } from '../../../lib/components/loading';
import SchoolAutoSuggest from '../../SchoolAutoSuggest/SchoolAutoSuggest';
import { MathLevelChoices, PrivacyTos, GradeChoices, OnBoardingInput, Header, SpinningRecordImage, TimesTableImages } from '../Components'
import { onSubmitDTCUser } from '../Helpers';

const BoxShadowCss = css`
    @media (min-width: 640px) {
        box-shadow: 0px 12px 24px 0px rgba(20, 20, 43, 0.13), 0px -2px 4px 0px rgba(20, 20, 43, 0.02), 0px 3px 14px 0px rgba(74, 58, 255, 0.03);

    }
`;

const CreateMathPlaylist = ({ setOnboardState, isOpen, teacher = false }) => {

    const { track } = useAnalytics('Setup Child Profile', 'Onboarding', { step: 'Setup Child Profile' });
    const [state, setState] = useState(null);
    const { updateUser } = useUserAction();
    const stageFinished = useOnboardStageFinished();
    const [schoolNotSelected, setSchoolNotSelected] = useState(false);
    const [selectedGrade, setGrade] = useState(null);
    const isSmall = useMedia('(max-width: 639px)');
    const user = useUser();
    const timesTableProduct = useProduct('times-tables');

    const showMathLevel = !teacher && !timesTableProduct;
    // school = school name, selectedSchool = school object
    const [school, setSchool] = useState('');
    const [selectedSchool, setSelectedSchool] = useState(null);
    // get the initial show email value
    const [showEmail, setShowEmail] = useState(user?.email?.length <= 0);

    const defaultValues = {
        level: 'at',
        terms: false,
        user_name: user.username ?? user.email
    };
    if (showEmail) {
        defaultValues.email = user.email;
    }
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors, trigger, control, setValue } = useForm({ defaultValues });
    const buttonCopy = teacher || timesTableProduct ? 'Create Account' : 'Go to Playlist';
    const formSubCopy = teacher ? '(This is the name your students will see on Muzology.)' : 'Child’s Name (optional)';

    useEffect(() => {
        isOpen && setError('grade', {
            type: 'manual',
            message: ''
        });
    }, [isOpen, setError]);

    // store grade and level choices in references
    const gradeChoice = useRef({});
    const levelChoice = useRef({});
    gradeChoice.current = watch('grade', '');

    const currentLevel = watch('level', '');
    levelChoice.current = currentLevel;
    console.log('LEVEL:', levelChoice.current);


    // user selects the state
    const selectState = (data) => {
        setState(data.abbreviation);
        clearErrors('state');
    };

    const selectGradeMobile = (data) => {
        setValue('grade', data, { shouldDirty: true });
        setGrade(data);
        clearErrors('grade');
    };

    // parent submits onboard form
    const [saving, setSaving] = useState(false);

    const onSubmit = (data) => {
        onSubmitDTCUser(data, {
            timesTableProduct,
            setSaving,
            setError,
            track,
            updateUser,
            setOnboardState,
            isSmall,
            selectedGrade
        });
    };

    // teacher submits onboard form
    const teacherOnSubmit = async (data) => {
        if (!selectedSchool) {
            // setError('school', { type: 'manual', message: 'This field is required' });
            setSchoolNotSelected(true);
            return;
        }
        setSchoolNotSelected(false);
        const payload = { ...data, state, school: selectedSchool?.pk };
        try {
            console.log('Payload:', payload);
            await stageFinished(ONBOARD_STAGE_AGREE_TO_TERMS, payload);
            // update the user's state
            updateUser();
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <div tw='relative w-full mx-auto max-w-[380px] sm:max-w-[600px] md:max-w-[660px] lg:max-w-full overflow-auto'>
            <div tw='bg-white rounded-lg py-5 px-5 sm:px-6 xl:px-8 font-Poppins relative overflow-hidden'>
                <BackgroundImage image={timesTableProduct ? TimesTablesCreateMathPlaylistBackground : CreateMathPlaylistBackground} tw=' hidden sm:block absolute inset-0 rounded-lg' />
                <LoadingOverlay show={saving} fixed={false} background='#35107688' />
                <div tw='w-full  flex flex-col lg:flex-row items-center lg:items-stretch gap-4 lg:gap-0 pt-0 lg:pt-5'>
                    <div tw='w-full lg:w-[44%] relative'>
                        <Header timesTableProduct={timesTableProduct} teacher={teacher} />

                        {/* Hero Style Images in Left Column For Times Table Onboard */}
                        {timesTableProduct && <TimesTableImages />}

                        {!timesTableProduct && <SpinningRecordImage />}

                    </div>
                    <div tw='w-full lg:w-[56%] relative  '>
                        <div
                            tw='rounded-lg py-0 sm:py-9 bg-white font-["Poppins"] w-full px-0 sm:px-4 lg:px-6 xl:px-9 max-w-[600px] lg:max-w-[700px] overflow-clip z-20 lg:-mt-5 mx-auto'
                            css={[BoxShadowCss]}
                        >
                            <form onSubmit={handleSubmit(teacher ? teacherOnSubmit : onSubmit)}>

                                {showEmail &&
                                    <div tw='mb-4 md:mb-5 '>
                                        <OnBoardingInput label={'Your Email'} register={register} name={'email'} required={true} />
                                        {formErrors.email && <div tw='text-red-500 font-bold mt-1'>
                                            {formErrors.email.message?.length ? formErrors.email.message : 'Please enter an email address.'}
                                        </div>}
                                    </div>
                                }

                                {/* Username Input */}
                                {!teacher &&
                                    <div tw='mb-4 md:mb-5'>
                                        <OnBoardingInput label={'Username'} register={register} name={'user_name'} required={true} />
                                        {formErrors.username?.message &&
                                            <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.username?.message}</div>}
                                        {formErrors.user_name &&
                                            <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>Please enter a username for your child.</div>}
                                    </div>
                                }

                                {/* Password Input */}
                                {!teacher &&
                                    <div tw='mb-4 md:mb-5'>
                                        <OnBoardingInput label={'Password'} register={register} name={'password'} required={true} />
                                        {formErrors.password && <div
                                            tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.password.message?.length ? formErrors.password.message : 'Password must have at least 6 characters'}</div>}
                                    </div>
                                }

                                {/* Childs Name B2B Full Muzology Sub || Teacher Name -- Both Optional */}
                                {!timesTableProduct &&
                                    <div tw='mb-4 md:mb-5'>
                                        <OnBoardingInput label={formSubCopy} register={register} name={'name'} teacher={teacher} required={false} />
                                    </div>
                                }

                                {/* Grade Choices for Parents and Teacher - Desktop and Mobile DropDown */}
                                <GradeChoices timesTableProduct={timesTableProduct} gradeChoice={gradeChoice} teacher={teacher} register={register} clearErrors={clearErrors} formErrors={formErrors} selectGradeMobile={selectGradeMobile} selectedGrade={selectedGrade} />

                                {teacher &&
                                    <div tw='relative w-full'>
                                        <div tw='mb-3 text-base font-normal text-[#303030] text-justify'> Your School</div>
                                        <SchoolAutoSuggest
                                            value={school}
                                            onChange={setSchool}
                                            onSelect={(s) => {
                                                console.log('School selected:', s);
                                                setSelectedSchool(s);
                                                if (s) {
                                                    setSchoolNotSelected(false);
                                                }
                                            }}
                                            selected={selectedSchool}
                                        />
                                        {schoolNotSelected && <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-0'>Please select your school.</div>}
                                    </div>
                                }

                                {/* Childs Name TT sub only */}
                                {timesTableProduct &&
                                    <div tw='mb-4 md:mb-5 pt-4'>
                                        <OnBoardingInput label={formSubCopy} register={register} name={'name'} teacher={teacher} required={false} />
                                    </div>
                                }

                                {showMathLevel &&
                                    <div tw='pt-4 pb-4'>
                                        <MathLevelChoices register={register} currentLevel={currentLevel} />
                                    </div>
                                }

                                {/* Privacy Policy and TOS */}
                                <div tw='pt-4'>
                                    <PrivacyTos register={register} formErrors={formErrors} />
                                </div>


                                <div tw='w-full flex justify-center  pt-7 md:pt-[43px]'>
                                    <button
                                        tw='w-full max-w-[280px] m-auto h-[62px] p-0 py-1 bg-[#6F5FE6] font-semibold text-xl text-white rounded-2xl border-solid border-2 sm:border-white justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-100 transform transition-all duration-100 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'
                                        type='submit'
                                        onClick={(event) => {
                                            clearErrors();
                                            trigger();
                                        }}
                                    >
                                        {buttonCopy}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default CreateMathPlaylist;