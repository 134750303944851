import React, { useState } from 'react';
import tw from 'twin.macro';
import { Modal, LoginButton } from 'components';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import EmailValidator from 'email-validator';
import { apiLoginClient } from 'context/Auth/AuthProvider';

const PasswordEmailSuccessScreen = ({ setIsOpen, setPasswordEmailSuccess, emailString }) => {
    const handleClose = () => {
        setPasswordEmailSuccess(false);
        setIsOpen(false);
    };
    return (
        <>
            <div tw='bg-[#3E257A] font-Poppins flex flex-col items-center rounded-t-2xl relative '>
                <div tw='text-white text-xl font-semibold text-center max-w-[440px] mt-10 '>Password Reset</div>
                <div tw='text-white text-sm sm:text-base font-normal text-center max-w-[440px] mt-4 '>We’ve sent an email to {emailString}. Please click the link in that email to reset your password.</div>
                <div tw='text-white text-sm sm:text-base font-normal text-center max-w-[450px] mt-4 pb-8  '> If you don’t receive an email shortly, check your spam folder or try again.</div>
                <div tw='absolute top-3 right-3 cursor-pointer' onClick={handleClose}>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M16.3398 9.3218C16.4358 9.23224 16.5133 9.12464 16.5678 9.00513C16.6223 8.88563 16.6527 8.75656 16.6573 8.62531C16.6619 8.49405 16.6406 8.36318 16.5946 8.24015C16.5486 8.11713 16.4788 8.00437 16.3893 7.90831C16.2997 7.81224 16.1921 7.73476 16.0726 7.68029C15.9531 7.62581 15.824 7.59541 15.6928 7.59081C15.5615 7.58621 15.4306 7.60751 15.3076 7.6535C15.1846 7.69948 15.0718 7.76924 14.9758 7.85881L12.0498 10.5868L9.32176 7.65981C9.13923 7.47285 8.89078 7.36476 8.62956 7.35867C8.36834 7.35258 8.11512 7.44897 7.92407 7.62721C7.73302 7.80545 7.61932 8.05139 7.6073 8.3124C7.59529 8.57341 7.6859 8.82876 7.85977 9.02381L10.5878 11.9498L7.66077 14.6778C7.56131 14.7665 7.48057 14.8741 7.4233 14.9944C7.36602 15.1147 7.33336 15.2452 7.32723 15.3783C7.3211 15.5114 7.34163 15.6444 7.38761 15.7694C7.43359 15.8944 7.5041 16.009 7.59498 16.1065C7.68587 16.2039 7.79531 16.2822 7.91687 16.3367C8.03843 16.3912 8.16966 16.4209 8.30286 16.424C8.43605 16.4271 8.56853 16.4036 8.6925 16.3548C8.81647 16.306 8.92944 16.2329 9.02476 16.1398L11.9508 13.4128L14.6788 16.3388C14.7668 16.4401 14.8744 16.5226 14.9951 16.5814C15.1157 16.6403 15.247 16.6742 15.381 16.6812C15.5151 16.6883 15.6492 16.6682 15.7753 16.6223C15.9015 16.5764 16.0171 16.5056 16.1153 16.414C16.2134 16.3225 16.2922 16.2121 16.3468 16.0895C16.4014 15.9669 16.4308 15.8345 16.4332 15.7003C16.4356 15.5661 16.4109 15.4328 16.3607 15.3083C16.3104 15.1839 16.2356 15.0708 16.1408 14.9758L13.4138 12.0498L16.3398 9.3218Z' fill='#9D91BB' />
                        <path fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z' fill='#9D91BB' />
                    </svg>
                </div>
            </div>
            <div tw='bg-[#6441B9] text-center text-white text-sm font-normal  pb-8 pt-4 rounded-b-2xl font-Poppins'>
                <div tw='text-[#9D91BB] font-normal text-sm mt-4 w-full mx-auto '> (If you need additional help, please contact us at <a tw='cursor-pointer underline' href='mailto:support@muzology.com'>support@muzology.com</a>.)</div>
            </div>
        </>
    );
};

const ResetEmailScreen = ({ onSubmit, handleSubmit, setIsOpen, register, errors }) => {
    return (
        <>
            <div tw='bg-[#3E257A] font-Poppins flex flex-col items-center rounded-t-2xl relative px-3'>
                <div tw='text-white text-xl font-semibold text-center max-w-[380px] mt-10 '>Parents / Educators</div>
                <div tw='text-[#ecebf5] font-normal text-sm mt-1'>Enter your email address to reset your password.</div>
                <form tw='w-full max-w-[350px] pb-9' onSubmit={handleSubmit(onSubmit)}>
                    <div tw='relative mt-5'>
                        <input
                            name='email'
                            ref={register({
                                required: true,
                                validate: (value) => EmailValidator.validate(value) || 'Enter a valid email'
                            })}
                            placeholder='Email'
                            tw='block w-full pl-4 pr-4 py-2.5 mt-2 font-normal text-base text-white bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#dfdce6] focus:outline-none focus:ring'
                        />
                        {errors.email && <div tw='pt-2 text-red-500'>* This field is required.</div>}
                    </div>
                    <div tw='mt-5'>
                        <LoginButton
                            id='login-button'
                            data-testid='login-button'
                            variant='Blue'

                        >
                            <div tw='flex items-center justify-center w-full'>
                                <div tw='ml-2'>
                                    Reset Password
                                </div>
                            </div>

                        </LoginButton>
                    </div>
                </form>
                <div tw='absolute top-3 right-3' onClick={() => setIsOpen(false)}>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M16.3398 9.3218C16.4358 9.23224 16.5133 9.12464 16.5678 9.00513C16.6223 8.88563 16.6527 8.75656 16.6573 8.62531C16.6619 8.49405 16.6406 8.36318 16.5946 8.24015C16.5486 8.11713 16.4788 8.00437 16.3893 7.90831C16.2997 7.81224 16.1921 7.73476 16.0726 7.68029C15.9531 7.62581 15.824 7.59541 15.6928 7.59081C15.5615 7.58621 15.4306 7.60751 15.3076 7.6535C15.1846 7.69948 15.0718 7.76924 14.9758 7.85881L12.0498 10.5868L9.32176 7.65981C9.13923 7.47285 8.89078 7.36476 8.62956 7.35867C8.36834 7.35258 8.11512 7.44897 7.92407 7.62721C7.73302 7.80545 7.61932 8.05139 7.6073 8.3124C7.59529 8.57341 7.6859 8.82876 7.85977 9.02381L10.5878 11.9498L7.66077 14.6778C7.56131 14.7665 7.48057 14.8741 7.4233 14.9944C7.36602 15.1147 7.33336 15.2452 7.32723 15.3783C7.3211 15.5114 7.34163 15.6444 7.38761 15.7694C7.43359 15.8944 7.5041 16.009 7.59498 16.1065C7.68587 16.2039 7.79531 16.2822 7.91687 16.3367C8.03843 16.3912 8.16966 16.4209 8.30286 16.424C8.43605 16.4271 8.56853 16.4036 8.6925 16.3548C8.81647 16.306 8.92944 16.2329 9.02476 16.1398L11.9508 13.4128L14.6788 16.3388C14.7668 16.4401 14.8744 16.5226 14.9951 16.5814C15.1157 16.6403 15.247 16.6742 15.381 16.6812C15.5151 16.6883 15.6492 16.6682 15.7753 16.6223C15.9015 16.5764 16.0171 16.5056 16.1153 16.414C16.2134 16.3225 16.2922 16.2121 16.3468 16.0895C16.4014 15.9669 16.4308 15.8345 16.4332 15.7003C16.4356 15.5661 16.4109 15.4328 16.3607 15.3083C16.3104 15.1839 16.2356 15.0708 16.1408 14.9758L13.4138 12.0498L16.3398 9.3218Z' fill='#9D91BB' />
                        <path fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z' fill='#9D91BB' />
                    </svg>
                </div>
            </div>

            <div tw='bg-[#6441B9] text-center text-white text-sm font-normal  pb-5 pt-4 font-Poppins px-3'>

                <div tw='text-white text-xl font-semibold text-center  w-full mx-auto max-w-[380px]'>
                    Students - Need Help?
                </div>

                <div tw='text-[#ecebf5] font-normal text-sm mt-1 w-full mx-auto max-w-[430px]'>
                    If your teacher created your account, they can reset your password or tell you your username (by going to Classes/Student Roster).
                    <div tw='mt-1.5' />
                    If your parent/guardian created your account, reset your password using the field above by entering the email they used to create your Muzology account.
                    <br />
                </div>

            </div>

            <div tw='bg-[#3E257A] text-center text-white text-sm font-normal  pb-5 pt-4 rounded-b-2xl font-Poppins px-3'>

                <div tw='text-white font-normal text-sm w-full mx-auto max-w-[430px] mt-0'>
                    Need Help? Contact us at - <a href='mailto:support@muzology.com' target='_blank' rel='noreferrer' tw='underline'>
                        support@muzology.com
                    </a></div>
            </div>
        </>
    );
};

const ForgotPassword = ({ isOpen, setIsOpen, isLoading, setIsLoading }) => {
    const [PasswordEmailSuccess, setPasswordEmailSuccess] = useState(false);
    const [emailString, setEmailString] = useState('');
    console.log("🚀 ~ ForgotPassword ~ emailString:", emailString)
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [{ loading: isPosting }, postPasswordReset] = useAxios(
        {
            url: 'api/password_reset/',
            method: 'POST',
            options: { axios: apiLoginClient }
        },
        { manual: true }
    );

    const onSubmit = (data) => {
        console.log("🚀 ~ onSubmit ~ data:", data)
        setIsLoading(true);
        postPasswordReset({ data: data })
            .then((response) => {
                setIsLoading(false);
                console.log(response);
                if (response.status === 200) {
                    setEmailString(data.email);
                    setPasswordEmailSuccess(true);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('Password Reset error:', error);
                console.error(error);
            });
    };

    const handleClose = () => {
        setPasswordEmailSuccess(false);
        setIsOpen(false);
    };



    return (
        <Modal isOpen={isOpen} setIsOpen={handleClose} modalChildrenStyle={tw`w-full max-w-[700px] mx-4 md:mx-auto`} modalChildrenStyleOpacity={tw`opacity-80`}>
            {PasswordEmailSuccess ? <PasswordEmailSuccessScreen setIsOpen={setIsOpen} setPasswordEmailSuccess={setPasswordEmailSuccess} emailString={emailString}/> : <ResetEmailScreen setIsOpen={setIsOpen} onSubmit={onSubmit} handleSubmit={handleSubmit} register={register} errors={errors} />}
        </Modal>
    );
};

export default ForgotPassword;