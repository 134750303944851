import { apiClient, handleAPIError } from 'utils/api-client';
import { css } from 'twin.macro';

// box shadow used on the form container 
export const BoxShadowCss = css`
    @media (min-width: 640px) {
        box-shadow: 0px 12px 24px 0px rgba(20, 20, 43, 0.13), 0px -2px 4px 0px rgba(20, 20, 43, 0.02), 0px 3px 14px 0px rgba(74, 58, 255, 0.03);

    }
`;

// handles parent submits onboard form
export const onSubmitDTCUser = (data, {
    timesTableProduct, // is times table user
    setSaving, // set saving state
    setError, // set error state
    track, // track analytics
    updateUser, // update user state
    setOnboardState, // update onboard state
    onboardFlag, // specific flag to set
    isSmall = false, //if on small device get grade from data form instead of selectedGrade state
    selectedGrade = null // handle selected grade that doesn't come from data
}) => {


    const payload = {};
    if (timesTableProduct) {
        payload.email = data.email;
        payload.username = data.user_name;
        payload.password = data.password;
        payload.name = data.name;
        payload.grade = isSmall ? selectedGrade : data.grade;
        payload.terms = data.terms;
        // check if this is for the account creation submit
    } else if (onboardFlag.all_access_account_creation === false) {
        payload.username = data.user_name;
        payload.password = data.password;
        payload.terms = data.terms;
    } else {
        payload.name = data.name;
        payload.grade = isSmall ? selectedGrade : data.grade;
        payload.proficiency_level = data.level;
    }
    setSaving(true);

    apiClient.post('/api/studentprofile/', payload)
        .then(response => {
            // student profile created
            const { data } = response;
            const { user, errors = null } = data;

            // check for error
            if (errors) {
                for (const key in errors) {
                    console.log('error', key, '=', errors[key]);
                    setError(key, { type: 'manual', message: errors[key] });
                }
                console.error('Form errors: ', errors);
                setSaving(false);
                return;
            }

            // onboarding complete
            if (!user?.id) {
                console.error('error saving proficiency level', data);
                setSaving(false);
                return;
            }

            // track the event
            console.log('Student profile setup');
            track('Student Profile Created', {
                name: data.name,
                grade: data.grade,
                proficiency_level: data.level
            });

            // update student data on FE
            updateUser();

            // Onboarding complete - save the student and close the dialog
            track('Student Onboarding Complete', {
                name: data.name,
                grade: data.grade,
                proficiency_level: data.level
            });
            // setSaving(false)
            setOnboardState(onboardFlag);
        })
        .catch(err => {
            console.error(err);
            handleAPIError(err);
            setSaving(false);
        });
};


