import React, { useState, useMemo, useEffect } from 'react';
import tw, { css } from 'twin.macro';
import { Helmet } from 'react-helmet';
import { ChildHomeBanner, ChildHomeBannerNull, HomePageVideoRows, Modal, VideoCard, MobileVideoCardRow, TrailBannerContainerWrapper } from 'components';
import { useVideoStats, useRecentlyWatchedVideos, useFavoriteVideos } from 'state/Student/VideoStats';
import VideoSlider from 'components/VideoSlider';
import { useUser } from 'state/User/UserState';
import { useCourseAndVideos } from 'state/CatalogState';
import { useVideos } from 'state/CatalogState';
import { useLessons } from 'state/CatalogState';
import { useVideosWithStats } from 'state/Student/VideoStats';
import { useProduct } from 'state/User/UserState';
import { useAnalytics } from '../../../../analytics/AnalyticsProvider';
import { useGuidance } from '../../../../state/Guidance/GuidanceProvider';
import { useActivity } from '../../../../state/User/ActivityState';
import { getActivityStates } from '../utils/ChildActiveState';

const findLesson = (lessons, lessonId) => Object.values(lessons).find(l => l.id === lessonId);
const what_to_do_next_key = {
    below: 0,
    at: 1,
    above: 2
};

const ScrollBarStyle = css`

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }
`;


const HomeContainer = () => {
    // console.log('        <HomeContainer> render');
    useAnalytics('Home', 'Main', { variant: 'child', accountType: 'D2C', role: 'student' });

    const user = useUser();
    const lessons = useLessons();
    const videoStats = useVideoStats();
    const videoLibraryWithStats = useVideosWithStats();

    // TODO: get the correct course for times-table
    const timesTableProduct = useProduct('times-tables');
    const courseType = timesTableProduct ? 'b9c9297a-d351-4bfc-85fb-21e41ad344dc' : 'd240f7d3-5189-41fa-9a10-1192a5962da1';
    const course = useCourseAndVideos(courseType);
    const coursesNotLoaded = Object.entries(course ?? {}).length === 0;

    const { downloadActivity } = useActivity();
    const { getActions: getGuidanceActions } = useGuidance();

    useEffect(() => {
        console.log('        <HomeContainer> mount - fetching actions & activity');
        // download activity on mount
        downloadActivity();
        // refresh guided actions on mounted
        getGuidanceActions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get the active state of the child
    const { isLoading, nullContainer, semiActiveState } = useMemo(
        () => getActivityStates(videoStats),  // Calculate all activity states
        [videoStats]  // Recalculate when videoStats changes
    );
    

    // get videos specific to each video row
    const recentlyWatchedVideos = useRecentlyWatchedVideos();
    const favoriteVideos = useFavoriteVideos();
    const { videos: recommendedVideos, refresh: refreshVideos } = useGuidance();

    const showRefreshVideos = refreshVideos.length > 0;
    const showRecommendedVideos = !nullContainer && recommendedVideos.length > 0;
    const showRecentlyWatchedVideos = recentlyWatchedVideos.length > 0;
    const showFavoriteVideos = favoriteVideos.length > 0;

    // find what to do next groups by proficiency_level group
    // const [whatToDoNextVideoList, setNullWhatToDoNextVideoList] = useState([]);

    const whatToDoNextVideoList = useMemo(() => {
        const grade = user?.student?.grade ?? '3';
        const proficiency_level = user?.student?.proficiency_level ?? 'at';
        const groups = course?.groups ?? [];
        const filterGroups = groups.filter(item => item.parent === null) ?? [];
        const gradeKey = {
            0: 0,
            1: 0,
            2: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 4,
            7: 5,
            8: 6,
            9: 7,
            10: 8,
            11: 9,
            12: 10
        };

        const group_id = filterGroups[gradeKey[grade]]?.id;
        // if group has subgroups create a new arr
        const reduceCourseGroups = groups.reduce((acc, group) => {
            if (timesTableProduct) acc.push(group);
            if (group.parent === group_id) {
                acc.push(group);
            }
            return acc;
        }, []);

        const what_to_do_next_group_id = reduceCourseGroups[0]?.id;
        const reduceWhatToDoNextGroups = groups.reduce((acc, group) => {
            if (group.parent === what_to_do_next_group_id) {
                acc.push(group);
            }
            return acc;
        }, []);

        // find what to do next groups by proficiency_level group
        const WhatToDoNextGroup = reduceWhatToDoNextGroups[what_to_do_next_key[proficiency_level]];
        const WhatToDoNextGroupGroupVideos = WhatToDoNextGroup?.lessons.map(lessonId => findLesson(lessons, lessonId)).filter(l => l);
        const nullWhatToDoNextVideoList = videoLibraryWithStats.reduce((acc, video) => {
            WhatToDoNextGroupGroupVideos?.map((group) => {
                if (group.video === video.id) acc.push(video);
            });
            return acc;
        }, []);

        console.log('<HomeContainer> nullWhatToDoNextVideoList', nullWhatToDoNextVideoList);
        return nullWhatToDoNextVideoList;
    }, [user?.student, course?.groups, lessons, videoLibraryWithStats, timesTableProduct]);

    const gradeVideoRows = useMemo(() => {

        // const coursesNotLoaded = Object.entries(nullCourse).length === 0;
        if (coursesNotLoaded) return null;

        const filterGroups = course?.groups?.filter(item => item.parent === null) ?? [];
        const gradeKey = {
            0: 0,
            1: 0,
            2: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 4,
            7: 5,
            8: 6,
            9: 7,
            10: 8,
            11: 9,
            12: 10
        };
        const group_id = filterGroups[gradeKey[user?.student.grade]]?.id;
        const classGroup = filterGroups.find(group => group.id === group_id);
        // if group has subgroups create a new arr
        const reduceCourseGroups = course?.groups?.reduce((acc, group) => {
            if (timesTableProduct) acc.push(group);
            if (group.parent === group_id) {
                acc.push(group);
            }
            return acc;
        }, []);

        // const what_to_do_next_group_id = reduceCourseGroups[0]?.id;
        // const reduceWhatToDoNextGroups = course?.groups?.reduce((acc, group) => {
        //     if (group.parent === what_to_do_next_group_id) {
        //         acc.push(group);
        //     }
        //     return acc;
        // }, []);
        //
        // find what to do next groups by proficiency_level group
        // const WhatToDoNextGroup = reduceWhatToDoNextGroups[what_to_do_next_key[user?.student.proficiency_level]];
        // const WhatToDoNextGroupGroupVideos = WhatToDoNextGroup?.lessons.map(lessonId => findLesson(lessons, lessonId)).filter(l => l);
        // const nullWhatToDoNextVideoList = videoLibraryWithStats.reduce((acc, video) => {
        //     WhatToDoNextGroupGroupVideos?.map((group) => {
        //         if (group.video === video.id) acc.push(video);
        //     });
        //     return acc;
        // }, []);
        // setNullWhatToDoNextVideoList(nullWhatToDoNextVideoList);

        return reduceCourseGroups.map((group, index) => {
            if (index === 0 && !timesTableProduct) return null;

            // find videos once they are populated in the playlist
            let groupLessons = group?.lessons.map(lessonId => findLesson(lessons, lessonId)).filter(l => l);
            let groupVideos = groupLessons.map(lesson => {
                const v = videoLibraryWithStats?.find(video => video.id === lesson.video);
                if (!v) {
                    console.log('video not found', lesson.video);
                }
                return v;
            });
            let videoRowVideos = groupVideos.filter(v => v);
            if (!videoRowVideos.length)
                return null;
            return (
                <React.Fragment key={group.id}>
                    <div tw='mt-14 hidden md:block'>
                        <HomePageVideoRows title={group?.title} videos={videoRowVideos} timesTableProduct={timesTableProduct} />
                    </div>
                    <div tw='mt-10  md:hidden'>
                        <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>{group?.title}</h2>
                        <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={videoRowVideos} /> </div>

                    </div>
                </React.Fragment>
            );
        });
    }, [coursesNotLoaded, course, user?.student.grade, user?.student.proficiency_level, videoLibraryWithStats, lessons, timesTableProduct]);

    // Show loading state if data isn't ready from child active state
    if (isLoading) {
        return 'Loading...';
    }

    return (
        <TrailBannerContainerWrapper>
            <div tw='h-full min-h-full overflow-hidden px-0 xl:px-6 py-3 sm:py-6 bg-[#1C0057] font-Poppins '>
                <Helmet>
                    <title>Home</title>
                </Helmet>
                <div tw='[max-width:var(--layout-width)] mx-auto max-h-full hidden md:block'>
                    <ChildHomeBanner semiActiveState={semiActiveState} nullState={nullContainer} nullWhatToDoNextVideoList={whatToDoNextVideoList} />
                    {(nullContainer && !timesTableProduct) && <div tw='mt-10'>
                        <HomePageVideoRows title='Videos You Might Like' videos={recommendedVideos?.slice(0, 2)} />
                    </div>}

                    {showRecentlyWatchedVideos && <div tw='mt-14'>
                        <HomePageVideoRows title='Recently Watched' videos={recentlyWatchedVideos} recently_watched={true} timesTableProduct={timesTableProduct} />
                    </div>}
                    {showRecommendedVideos && <div tw='mt-10'>
                        <HomePageVideoRows title='Videos You Might Like' videos={recommendedVideos?.slice(0, 2)} />
                    </div>}

                    {showFavoriteVideos && <div tw='mt-10'>
                        <HomePageVideoRows
                            title='Favorites Playlist' videos={favoriteVideos}
                            favorite={true} timesTableProduct={timesTableProduct} />
                    </div>}
                    {showRefreshVideos && <div tw='mt-14'>
                        <HomePageVideoRows title='Refresh Your Memory' videos={refreshVideos} timesTableProduct={timesTableProduct} />
                    </div>}

                    {gradeVideoRows}
                </div>

                <div tw='[max-width:var(--layout-width)] mx-auto max-h-full md:hidden'>
                    <ChildHomeBanner semiActiveState={semiActiveState} nullState={nullContainer} nullWhatToDoNextVideoList={whatToDoNextVideoList} />

                    {(nullContainer && !timesTableProduct) &&
                        <div tw='mt-14'>
                            <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>Videos You Might Like</h2>
                            <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={recommendedVideos} /> </div>
                        </div>
                    }
                    {showRecentlyWatchedVideos &&
                        <div tw='mt-14 '>
                            <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>Recently Watched</h2>
                            <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={recentlyWatchedVideos} /> </div>
                        </div>
                    }
                    {showRecommendedVideos &&
                        <div tw='mt-14'>
                            {/* need videos from videos you might like algorithm */}
                            <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>Videos You Might Like</h2>
                            <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={recommendedVideos} /> </div>
                        </div>
                    }
                    {showFavoriteVideos &&
                        <div tw='mt-14'>
                            <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>Favorites Playlist</h2>
                            <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={favoriteVideos} /> </div>
                        </div>
                    }

                    {showRefreshVideos &&
                        <div tw='mt-14'>
                            <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>Refresh Your Memory</h2>
                            <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={refreshVideos} /> </div>
                        </div>
                    }
                    {gradeVideoRows}
                </div>
            </div>
        </TrailBannerContainerWrapper>
    );
};

export default HomeContainer;